import { ACTION_TYPES } from '@constants';
import { INotifications } from '@/Interfaces/Widget.interface';

// SINGLE ACTIONS
const setNotification = (payload: INotifications | null) => {
  return {
    type: ACTION_TYPES.SET_NOTIFICATION_MESSAGE,
    payload,
  };
};

const setLoading = (payload: boolean) => {
  return {
    type: ACTION_TYPES.SET_START_LOADING,
    payload,
  };
};

const setAlert = (payload: INotifications | null) => {
  return {
    type: ACTION_TYPES.SET_ALERT_MESSAGE,
    payload,
  };
};

// SET_START_LOADING

export default {
  setNotification,
  setLoading,
  setAlert,
};
